import format from 'date-fns/format'
import {graphql} from 'gatsby'
import {useIntl} from 'gatsby-plugin-intl'
import React from 'react'
import ImageGallery from 'react-image-gallery'
import withSizes from 'react-sizes'
import styled from 'styled-components'
import Layout from '../components/layout'
import {ImageGalleryStyle} from '../styles/gallery'

const mapSizesToProps = ({width}) => ({
    isMobile: width < 767,
})

const Container = styled.div`
  position: relative;
  z-index: 30;
`

const Title = styled.h3`
  background: ${props => props.theme.colors.caramelCloudIntense};
  color: ${props => props.theme.colors.cream};
  z-index: 1;
  padding: 1rem;
  margin-top: 0;
  @media all and (max-width: 767px) {
    padding: 0.5rem;
  }

  span {
    margin-left: 0.5rem;
  }
`

const AlbumTemplate = ({data, isMobile}) => {
    const intl = useIntl()
    const code = intl.locale.charAt(0).toUpperCase() + intl.locale.charAt(1)
    const locale = `title${code}`
    const title = data['contentfulAlbum'][locale]
    const {date} = data['contentfulAlbum']

    const ImageData = item => {
        return ({
            original: item.fluid.images.fallback.src,
            thumbnail: item.fixed.images.fallback.src,
            srcSet: item.fluid.images.sources.length ? item.fluid.images.sources[0].srcSet : item.fluid.images.fallback.srcSet,
            sizes: item.fluid.images.sources.length ? item.fluid.images.sources[0].sizes : item.fluid.images.fallback.sizes,
        })
    }
    const imageNodes = images => images.map(image => ImageData(image))
    const items = imageNodes(data['contentfulAlbum'].media)

    return (
        <Layout>
            <ImageGalleryStyle/>
            <Container>
                <ImageGallery
                    items={items}
                    showIndex={true}
                    preventDefaultTouchmoveEvent={false}
                    showThumbnails={!isMobile}
                />
                <Title>
                    {title}, <span>{format(new Date(date), 'DD/MM/YYYY')}</span>
                </Title>
            </Container>
        </Layout>
    )
}

export default withSizes(mapSizesToProps)(AlbumTemplate)

export const pageQuery = graphql`
  query($name: String!) {
    contentfulAlbum(name: { eq: $name }) {
      media {
        fluid: gatsbyImageData(layout: FULL_WIDTH)
        fixed: gatsbyImageData(layout: FIXED, width: 150)
      }
      titleEn
      titleRu
      titleNl
      date
    }
  }
`
